.cm-mergeView {
    @extend .cm-mergeView;
    height: 500px !important;
    max-height: 500px !important;
}

// .cm-panels-bottom {
//     @extend .cm-panels-bottom;
//     top: 500px !important;
// }
