.dnd-ghost {
    border: "1px solid hotpink !important";
    opacity: 0.4;
    cursor: "grabbing";
}
.dnd-chosen {
    border: "1px solid hotpink !important";
    cursor: "grabbing";
}
.dnd-drag {
    border: "1px solid hotpink !important";
    color: hotpink;
    cursor: "grabbing";
}
