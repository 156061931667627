// for the notch
html,
.modal-dialog {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.offcanvas {
    min-height: calc(100% + env(safe-area-inset-top));
    margin: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.full-height-container {
    height: calc(
        100vh - (env(safe-area-inset-bottom) + env(safe-area-inset-top))
    );
}

.custom-link-btn-muted {
    @extend .btn;
    @extend .btn-link;
    @extend .text-body-secondary;
    text-decoration: none;
    &:hover {
        color: var(--bs-link-color) !important;
    }
}
.custom-link-btn-danger {
    @extend .btn;
    @extend .btn-link;
    @extend .text-danger;
    text-decoration: none;
}

.custom-link-btn-info {
    @extend .btn;
    @extend .btn-link;
    @extend .text-info;
    text-decoration: none;
}

.custom-link-btn-hover {
    @extend .btn;
    @extend .btn-link;
    text-decoration: none;
    &:hover {
        color: var(--bs-link-color) !important;
    }
}

.custom-link-btn-hover-primary-muted {
    @extend .btn;
    @extend .btn-link;
    color: var(--bs-secondary) !important;
    text-decoration: none;
    &:hover {
        color: var(--bs-link-color) !important;
    }
}

.custom-link-hover-primary {
    color: var(--bs-secondary) !important;
    text-decoration: none;
    &:hover {
        color: var(--bs-link-color) !important;
    }
}

.custom-link-btn-hover-primary {
    @extend .btn;
    @extend .btn-link;
    color: var(--bs-heading-color) !important;
    text-decoration: none;
    &:hover {
        color: var(--bs-link-color) !important;
    }
}

.custom-link-btn-success {
    @extend .btn;
    @extend .btn-link;
    @extend .text-success;
    text-decoration: none;
}

.custom-link-btn-warning {
    @extend .btn;
    @extend .btn-link;
    @extend .text-warning;
    text-decoration: none;
}
.hover-opacity {
    &:hover {
        opacity: 0.6;
    }
}

h1.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: center;
    // font-size: 48px;
}
h2.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 1em;
    font-style: italic;
    margin-top: 28px;
    text-align: right;
}
h3.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: right;
}
h4.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: right;
}
h5.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: right;
}
h6.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
    text-align: right;
}
p.ms {
    font-family: Georgia, "Times New Roman", Times, serif;
}

.info-action-panel-accordion-header > .accordion-button {
    padding: 12px 12px 12px 12px !important;
}
.draggable-list-group-item {
    @extend .list-group-item;
    &:hover {
        cursor: grab;
    }
    &:active:hover {
        cursor: grabbing;
    }
}

// This removes the arrow next to the react-bootstrap dropdown menu
.dropdown-toggle::after {
    display: none !important;
}
.dropdown-toggle::before {
    display: none !important;
}

.dropdown-menu {
    z-index: 99999 !important;
}

.toolbar-icon {
    &:hover {
        opacity: 0.6;
    }
}

.issue-description-markdown-field {
    padding: 6px 12px;
    min-height: 134px;
}

.rotate-90-deg {
    transform: rotate(0.25turn);
}
