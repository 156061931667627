.custom-badge-pink {
    @extend .badge;
    background-color: $pink !important;
}
.custom-badge-red {
    @extend .badge;
    background-color: $red !important;
}
.custom-badge-orange {
    @extend .badge;
    background-color: $orange !important;
    color: $dark;
}
.custom-badge-yellow {
    @extend .badge;
    background-color: $yellow !important;
    color: $dark;
}
.custom-badge-green {
    @extend .badge;
    background-color: $green !important;
}
.custom-badge-teal {
    @extend .badge;
    background-color: $teal !important;
    color: $dark;
}
.custom-badge-cyan {
    @extend .badge;
    background-color: $cyan !important;
    color: $dark;
}
.custom-badge-blue {
    @extend .badge;
    background-color: $blue !important;
}
.custom-badge-indigo {
    @extend .badge;
    background-color: $indigo !important;
}
.custom-badge-violet {
    @extend .badge;
    background-color: $purple !important;
}
.custom-badge-light {
    @extend .badge;
    background-color: $light !important;
    color: $dark;
}
.custom-badge-gray {
    @extend .badge;
    background-color: $secondary !important;
}
.custom-badge-dark {
    @extend .badge;
    background-color: $dark !important;
    color: $light;
}

.btn-custom-badge-pink {
    @extend .btn;
    background-color: $pink !important;
    color: $light;
}
.show.btn-custom-badge-pink {
    border-color: darken($pink, 10%) !important;
    color: $light;
}
.btn-custom-badge-pink:hover {
    background-color: darken($pink, 10%) !important;
    color: $light;
}
.btn-custom-badge-pink:active {
    border-color: darken($pink, 10%) !important;
    background-color: darken($pink, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-pink:focus-visible {
    border-color: darken($pink, 10%) !important;
    background-color: darken($pink, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-red {
    @extend .btn;
    background-color: $red !important;
    color: $light;
}
.show.btn-custom-badge-red {
    border-color: darken($red, 10%) !important;
    color: $light;
}
.btn-custom-badge-red:hover {
    background-color: darken($red, 10%) !important;
    color: $light;
}
.btn-custom-badge-red:active {
    border-color: darken($red, 10%) !important;
    background-color: darken($red, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-red:focus-visible {
    border-color: darken($red, 10%) !important;
    background-color: darken($red, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-orange {
    @extend .btn;
    background-color: $orange !important;
    color: $dark;
}
.show.btn-custom-badge-orange {
    border-color: darken($orange, 10%) !important;
    color: $dark;
}
.btn-custom-badge-orange:hover {
    background-color: darken($orange, 10%) !important;
    color: $dark;
}
.btn-custom-badge-orange:active {
    border-color: darken($orange, 10%) !important;
    background-color: darken($orange, 10%) !important;
    color: $dark !important;
}
.btn-custom-badge-orange:focus-visible {
    border-color: darken($orange, 10%) !important;
    background-color: darken($orange, 10%) !important;
    color: $dark !important;
}

.btn-custom-badge-yellow {
    @extend .btn;
    background-color: $yellow !important;
    color: $dark;
}
.show.btn-custom-badge-yellow {
    border-color: darken($yellow, 10%) !important;
    color: $dark;
}
.btn-custom-badge-yellow:hover {
    background-color: darken($yellow, 10%) !important;
    color: $dark;
}
.btn-custom-badge-yellow:active {
    border-color: darken($yellow, 10%) !important;
    background-color: darken($yellow, 10%) !important;
    color: $dark !important;
}
.btn-custom-badge-yellow:focus-visible {
    border-color: darken($yellow, 10%) !important;
    background-color: darken($yellow, 10%) !important;
    color: $dark !important;
}

.btn-custom-badge-green {
    @extend .btn;
    background-color: $green !important;
    color: $light;
}
.show.btn-custom-badge-green {
    border-color: darken($green, 10%) !important;
    color: $light;
}
.btn-custom-badge-green:hover {
    background-color: darken($green, 10%) !important;
    color: $light;
}
.btn-custom-badge-green:active {
    border-color: darken($green, 10%) !important;
    background-color: darken($green, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-green:focus-visible {
    border-color: darken($green, 10%) !important;
    background-color: darken($green, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-teal {
    @extend .btn;
    background-color: $teal !important;
    color: $dark;
}
.show.btn-custom-badge-teal {
    border-color: darken($teal, 10%) !important;
    color: $dark;
}
.btn-custom-badge-teal:hover {
    background-color: darken($teal, 10%) !important;
    color: $dark;
}
.btn-custom-badge-teal:active {
    border-color: darken($teal, 10%) !important;
    background-color: darken($teal, 10%) !important;
    color: $dark !important;
}
.btn-custom-badge-teal:focus-visible {
    border-color: darken($teal, 10%) !important;
    background-color: darken($teal, 10%) !important;
    color: $dark !important;
}

.btn-custom-badge-cyan {
    @extend .btn;
    background-color: $cyan !important;
    color: $dark;
}
.show.btn-custom-badge-cyan {
    border-color: darken($cyan, 10%) !important;
    color: $dark;
}
.btn-custom-badge-cyan:hover {
    background-color: darken($cyan, 10%) !important;
    color: $dark;
}
.btn-custom-badge-cyan:active {
    border-color: darken($cyan, 10%) !important;
    background-color: darken($cyan, 10%) !important;
    color: $dark !important;
}
.btn-custom-badge-cyan:focus-visible {
    border-color: darken($cyan, 10%) !important;
    background-color: darken($cyan, 10%) !important;
    color: $dark !important;
}

.btn-custom-badge-blue {
    @extend .btn;
    background-color: $blue !important;
    color: $light;
}
.show.btn-custom-badge-blue {
    border-color: darken($blue, 10%) !important;
    color: $light;
}
.btn-custom-badge-blue:hover {
    background-color: darken($blue, 10%) !important;
    color: $light;
}
.btn-custom-badge-blue:active {
    border-color: darken($blue, 10%) !important;
    background-color: darken($blue, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-blue:focus-visible {
    border-color: darken($blue, 10%) !important;
    background-color: darken($blue, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-indigo {
    @extend .btn;
    background-color: $indigo !important;
    color: $light;
}
.show.btn-custom-badge-indigo {
    border-color: darken($indigo, 10%) !important;
    color: $light;
}
.btn-custom-badge-indigo:hover {
    background-color: darken($indigo, 10%) !important;
    color: $light;
}
.btn-custom-badge-indigo:active {
    border-color: darken($indigo, 10%) !important;
    background-color: darken($indigo, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-indigo:focus-visible {
    border-color: darken($indigo, 10%) !important;
    background-color: darken($indigo, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-purple {
    @extend .btn;
    background-color: $purple !important;
    color: $light;
}
.show.btn-custom-badge-purple {
    border-color: darken($purple, 10%) !important;
    color: $light;
}
.btn-custom-badge-purple:hover {
    background-color: darken($purple, 10%) !important;
    color: $light;
}
.btn-custom-badge-purple:active {
    border-color: darken($purple, 10%) !important;
    background-color: darken($purple, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-purple:focus-visible {
    border-color: darken($purple, 10%) !important;
    background-color: darken($purple, 10%) !important;
    color: $light !important;
}

.btn-custom-badge-light {
    @extend .btn;
    background-color: $light !important;
    color: $dark;
}
.show.btn-custom-badge-light {
    border-color: darken($light, 10%) !important;
    color: $dark;
}
.btn-custom-badge-light:hover {
    background-color: darken($light, 10%) !important;
    color: $dark;
}
.btn-custom-badge-light:active {
    background-color: darken($light, 10%) !important;
    color: $dark !important;
}
.btn-custom-badge-light:focus-visible {
    border-color: darken($light, 10%) !important;
    background-color: darken($light, 10%) !important;
    color: $dark !important;
}

.btn-custom-badge-dark {
    @extend .btn;
    background-color: $dark !important;
    color: $light;
}
.show.btn-custom-badge-dark {
    border-color: lighten($dark, 10%) !important;
    color: $light;
}
.btn-custom-badge-dark:hover {
    background-color: lighten($dark, 10%) !important;
    color: $light;
}
.btn-custom-badge-dark:active {
    border-color: lighten($dark, 10%) !important;
    background-color: lighten($dark, 10%) !important;
    color: $light !important;
}
.btn-custom-badge-dark:focus-visible {
    border-color: lighten($dark, 10%) !important;
    background-color: lighten($dark, 10%) !important;
    color: $light !important;
}
